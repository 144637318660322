<template>
  <div class="flex items-center justify-start">
    <span @click="back" class="flex items-center justify-center text-indigo-400 px-4 py-2 mr-2 cursor-pointer">
      <svg fill="currentColor" class="w-6 h-6 pr-1" viewBox="0 0 1024 1024" version="1.1"  p-id="1098"><path d="M800 448H237.3l201.4-201.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-256 256c-0.4 0.4-0.7 0.8-1.1 1.2-0.2 0.2-0.3 0.4-0.5 0.5-0.2 0.2-0.4 0.4-0.5 0.6-0.2 0.2-0.4 0.5-0.6 0.7-0.1 0.2-0.3 0.3-0.4 0.5-0.2 0.3-0.4 0.5-0.6 0.8-0.1 0.2-0.2 0.3-0.3 0.5l-0.6 0.9c-0.1 0.2-0.2 0.3-0.3 0.5-0.2 0.3-0.3 0.6-0.5 0.9-0.1 0.2-0.2 0.3-0.3 0.5-0.1 0.3-0.3 0.5-0.4 0.8l-0.3 0.6c-0.1 0.3-0.2 0.5-0.3 0.8-0.1 0.2-0.2 0.5-0.3 0.7-0.1 0.2-0.2 0.5-0.3 0.7-0.1 0.3-0.2 0.5-0.3 0.8-0.1 0.2-0.1 0.4-0.2 0.6l-0.3 0.9c-0.1 0.2-0.1 0.4-0.2 0.5-0.1 0.3-0.2 0.7-0.3 1 0 0.2-0.1 0.3-0.1 0.5-0.1 0.3-0.2 0.7-0.2 1 0 0.2-0.1 0.3-0.1 0.5-0.1 0.3-0.1 0.7-0.2 1 0 0.2 0 0.4-0.1 0.6 0 0.3-0.1 0.6-0.1 1 0 0.3 0 0.5-0.1 0.8 0 0.3 0 0.5-0.1 0.8v3.2c0 0.3 0 0.5 0.1 0.8 0 0.3 0 0.5 0.1 0.8 0 0.3 0.1 0.6 0.1 1 0 0.2 0 0.4 0.1 0.6 0.1 0.3 0.1 0.7 0.2 1 0 0.2 0.1 0.3 0.1 0.5 0.1 0.3 0.1 0.7 0.2 1 0 0.2 0.1 0.3 0.1 0.5 0.1 0.3 0.2 0.7 0.3 1 0.1 0.2 0.1 0.4 0.2 0.5l0.3 0.9c0.1 0.2 0.1 0.4 0.2 0.6 0.1 0.3 0.2 0.5 0.3 0.8 0.1 0.2 0.2 0.5 0.3 0.7 0.1 0.2 0.2 0.5 0.3 0.7 0.1 0.3 0.2 0.5 0.3 0.8l0.3 0.6c0.1 0.3 0.3 0.5 0.4 0.8 0.1 0.2 0.2 0.3 0.3 0.5 0.2 0.3 0.3 0.6 0.5 0.9 0.1 0.2 0.2 0.3 0.3 0.5l0.6 0.9c0.1 0.2 0.2 0.3 0.3 0.5 0.2 0.3 0.4 0.5 0.6 0.8 0.1 0.2 0.3 0.3 0.4 0.5 0.2 0.2 0.4 0.5 0.6 0.7 0.2 0.2 0.4 0.4 0.5 0.6 0.2 0.2 0.3 0.4 0.5 0.5 0.4 0.4 0.7 0.8 1.1 1.2l256 256c6.2 6.2 14.4 9.4 22.6 9.4s16.4-3.1 22.6-9.4c12.5-12.5 12.5-32.8 0-45.3L237.3 512H800c17.6 0 32 14.4 32 32v320c0 17.7 14.3 32 32 32s32-14.3 32-32V544c0-52.9-43.1-96-96-96z" p-id="1099"></path></svg>
      <span>返回</span>
    </span>
    <div class="pl-4 text-gray-600 text-xl border-l-2 border-indigo-400">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: "MTitle",
  props: { title: { type: String, required: true } },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
};
</script>