<template>
  <div id="app" class="w-full h-screen bg-gray-100">
    <Nav />
    <div class="flex w-full justify-center">
      <router-view class="w-full m-4" />
    </div>
  </div>
</template>

<script>
import Nav from "./components/Nav";

export default {
  name: "app",
  components: { Nav },
};
</script>

<style>
.main {
  margin-top: 20px;
}

.el-table .warning-row {
  background: #faf089;
}

.el-table .danger-row {
  background: #fbb6ce;
}
</style>
