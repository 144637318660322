<template>
  <div class="bg-white p-6 my-6">
    <m-title :title="title" />
    <slot name="form"></slot>
  </div>
</template>

<script>
import MTitle from './MTitle'
export default {
  name: "MForm",
  components: {MTitle},
  props: { title: { type: String, required: true } },
};
</script>