import { mapState } from "vuex";

export function filterMixin(storeName) {
  return {
    inject: ["tableBoard"],
    computed: {
      ...mapState({
        params: state => state[storeName].params
      }),
    },
    mounted() {
      if (Object.keys(this.params.filter).length === 0) {
        const params = { ...this.params, filter: { ...this.condition } }
        this.$store.commit(`${storeName}/updateParams`, params)
      }
    },
  }
}