<template>
  <div>
    <div class="flex justify-end py-2 text-sm text-indigo-500 cursor-pointer">
      <a @click="send_SWSB_SMS" class="px-2">发送税务申报短信</a>
      <a @click="send_GSNB_SMS" class="px-2">发送工商年报短信</a>
      <a @click="exportCSV" class="px-2">导出Excel</a>
      <a v-if="me.permisson >= 100" @click="batch" class="px-2">批量修改</a>
    </div>
    <el-table :data="tableData" :border="border" @selection-change="select">
      <el-table-column type="selection" :width="25"></el-table-column>

      <el-table-column prop="name" label="公司名称">
        <template slot-scope="scope">
          <div class="flex items-center">
            <span class="pr-1 text-indigo-400">{{ scope.$index + 1 }}</span>
            <el-link v-if="me.permisson >= 100" :underline="false" @click="edit(scope.row)">{{ scope.row.name
              }}</el-link>
            <span v-else>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="me.permisson >= 100" prop="agent_date" label="代理日期" width="90">
        <template slot-scope="scope">{{
          scope.row.agent_date | date
        }}</template>
      </el-table-column>

      <el-table-column v-if="me.permisson >= 100" prop="leave_time" label="离开日期" width="80">
        <template slot-scope="scope">{{
          scope.row.leave_time | date
        }}</template>
      </el-table-column>

      <el-table-column v-if="me.permisson >= 100" prop="cycle_months" label="收费周期" width="80">
        <template slot-scope="scope">
          <ConfigSelect v-model="scope.row.cycle_months" @input="change(scope.row, 'cycle_months')"
            config="cycleMonths" />
        </template>
      </el-table-column>
      <el-table-column v-if="me.permisson >= 100" prop="agent_fee" label="周期收费" width="45" />
      <el-table-column label="收费(月)" width="50">
        <template slot-scope="scope">{{
          _agent_fee_of_month(scope.row)
        }}</template>
      </el-table-column>
      <el-table-column prop="commission" label="提成比例" width="45"></el-table-column>
      <el-table-column label="提成(月)" width="50">
        <template slot-scope="scope">{{ _bonus(scope.row) }}</template>
      </el-table-column>

      <el-table-column label="开票提成(月)" width="50">
        <template slot-scope="scope">{{ _invoice_bonus(scope.row) }}</template>
      </el-table-column>

      <el-table-column prop="status" label="状态" width="65">
        <template slot-scope="scope">
          <el-switch v-if="me.permisson >= 100" v-model="scope.row.status" :disabled="me.permisson < 10"
            @change="change(scope.row, 'status')"></el-switch>
          <span v-else>{{ scope.row.status ? "激活" : "冻结" }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="category" label="类型" width="110">
        <template slot-scope="scope">
          <ConfigSelect v-model="scope.row.category" @input="change(scope.row, 'category')"
            config="companyCategories" />
        </template>
      </el-table-column>

      <!-- 太占内存
      <el-table-column prop="consumer" label="所有人" width="110">
        <template slot-scope="scope">
          <ModelSelect v-model="scope.row.consumer" @input="change(scope.row, 'consumer')"  model="consumer"/>
        </template>
      </el-table-column>
      -->
      <el-table-column prop="consumer" label="所有人" width="70">
        <template slot-scope="scope">{{ scope.row.consumer.name }}</template>
      </el-table-column>

      <el-table-column prop="user" label="代理人" width="100">
        <template slot-scope="scope">
          <ModelSelect v-if="me.permisson >= 100" v-model="scope.row.user" @input="change(scope.row, 'user')"
            model="user" />
          <span v-else>{{ scope.row.user_name }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="user_invoice" label="开票人" width="100">
        <template slot-scope="scope">
          <ModelSelect v-if="me.permisson >= 100" v-model="scope.row.user_invoice"
            @input="change(scope.row, 'user_invoice')" model="user" />
          <span v-else>{{ scope.row.user_invoice_name }}</span>
        </template>
      </el-table-column>


      <el-table-column prop="acquisition" label="获客方式" width="100">
        <template slot-scope="scope">
          <ConfigSelect v-model="scope.row.acquisition" @input="change(scope.row, 'acquisition')"
            config="acquisition" />
        </template>
      </el-table-column>
      <el-table-column prop="referrer" label="引荐人" width="100">
        <template slot-scope="scope">{{ scope.row.referrer_consumer_name }}
          {{ scope.row.referrer_name }}</template>
      </el-table-column>

      <el-table-column width="40" v-if="me.permisson >= 1000">
        <template slot-scope="scope">
          <DeleteButton :delete="deleteItem" :uid="scope.row.uid" />
        </template>
      </el-table-column>
    </el-table>

    <div class="flex my-2 items-center">
      <div v-if="me.permisson >= 100" class="flex items-center">
        <span class="text-gray-600 text-sm">月收费</span>
        <span class="text-indigo-400 px-1 italic tracking-wider bg-white">{{
          tableData
            .map((i) => parseInt(i.agent_fee / i.cycle_months))
            .reduce((a, b) => a + b, 0)
        }}</span>
      </div>

      <div class="flex items-center ml-4">
        <span class="text-gray-600 text-sm">代理提成</span>
        <span class="text-indigo-400 px-1 italic tracking-wider">{{
          parseInt(
            tableData
              .map((i) => ((i.agent_fee / i.cycle_months) * i.commission) / 100)
              .reduce((a, b) => a + b, 0)
          )
        }}</span>
      </div>

      <div class="flex items-center ml-4">
        <span class="text-gray-600 text-sm">开票提成</span>
        <span class="text-indigo-400 px-1 italic tracking-wider">{{
          parseInt(
            tableData
              .map(
                (i) =>
                  ((i.agent_fee / i.cycle_months) * i.invoice_commission) / 100
              )
              .reduce((a, b) => a + b, 0)
          )
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ModelSelect from "@/components/Select/ModelSelect";
import ConfigSelect from "@/components/Select/ConfigSelect";
import DeleteButton from "@/components/DeleteButton";
import { tableMixin } from "@/mixins/table";
import confirmMixin from "@/mixins/confirm";
import { filters, arrayToCSV } from "@/helper";
import { send_sms } from "@/api";

export default {
  mixins: [tableMixin("company"), confirmMixin],
  components: { ModelSelect, ConfigSelect, DeleteButton },
  filters: {
    ...filters,
  },
  computed: {
    tableData() {
      return this.items.map((i) => ({
        ...i,
        user: i.user.uid,
        user_invoice: i.user_invoice ? i.user_invoice.uid : null,
        user_name: i.user.name,
        user_invoice_name: i.user_invoice ? i.user_invoice.name : null,
        user_telphone: i.user.telphone,
        // consumer: i.consumer.uid
        referrer: i.referrer ? i.referrer.uid : null,
        referrer_name: i.referrer ? i.referrer.name : null,
        referrer_consumer_name: i.referrer_consumer
          ? i.referrer_consumer.name
          : null,
      }));
    },
  },
  mounted() {
    this.$store.dispatch("user/all");
    this.$store.dispatch("consumer/all");
  },
  methods: {
    _agent_fee_of_month(i) {
      return parseInt(i.agent_fee / i.cycle_months);
    },
    _bonus(i) {
      return parseInt(((i.agent_fee / i.cycle_months) * i.commission) / 100);
    },
    _invoice_bonus(i) {
      return parseInt(
        ((i.agent_fee / i.cycle_months) * i.invoice_commission) / 100
      );
    },
    _array_sum(array, idx) {
      return array.map((i) => i[idx]).reduce((a, b) => a + b, 0);
    },
    // 税务申报完成短信
    async send_SWSB_SMS() {
      const params_list = this.selected.map(function (i) {
        return [i.name, i.user_name, i.user_telphone];
      });
      this.sendSMS("561391", "税务申报完成", params_list);
    },
    // 工商年报完成短信
    async send_GSNB_SMS() {
      const me = this.me;
      const params_list = this.selected.map(function (i) {
        return [i.name, me.name, me.telphone];
      });
      this.sendSMS("592687", "工商年报完成", params_list);
    },
    // 批量发送短信
    async sendSMS(temp_id, sms_title, params_list) {
      if (this.selected.length < 1) {
        this.$notify.error({ message: "至少选择一行记录" });
        return false;
      }
      const tip = `给 ${this.selected[0].name} 等 ${this.selected.length} 家公司发送${sms_title}短信?`;
      this.confirm(tip)
        .then(async () => {
          const telphone_list = this.selected.map((i) => i.consumer.telphone);
          const res = await send_sms(temp_id, telphone_list, params_list);
          if (res.data.length > 0) {
            this.$notify.error({
              message: `以下短信发送失败：${res.data}`,
              duration: 0,
            });
          } else {
            this.$notify.success({ message: "短信全部发送成功" });
          }
        })
        .catch(() => this.$notify.info({ message: "已取消发送" }));
    },
    exportCSV() {
      const self = this;
      if (this.selected.length < 1) {
        this.$notify.error({ message: "至少选择一行记录" });
        return false;
      }
      const data = this.selected.map((i) => [
        i.name,
        i.consumer.name,
        filters.date(i.agent_date),
        i.agent_fee,
        i.cycle_months,
        self._agent_fee_of_month(i),
        i.user_name,
        i.user_invoice_name,
        i.commission,
        i.invoice_commission,
        self._bonus(i),
        self._invoice_bonus(i),
        i.acquisition,
        i.referrer_name,
      ]);

      const header = [
        "代理公司",
        "所有人",
        "开始代理日期",
        "周期收费",
        "周期月数",
        "月收费",
        "记账人",
        "开票人",
        "提成比例",
        "开票提成比例",
        "记账提成",
        "开票提成",
        "获客方式",
        "引荐人",
      ];
      const table = [header, ...data];
      arrayToCSV(table, `代理公司_${new Date().getTime()}`);
    },
  },
};
</script>
