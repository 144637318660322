export default {
  methods: {
    confirm(tip, yes="确定", no="取消") {
      return new Promise((resolve, reject) => {
        this.$confirm(tip, '提示', {
          confirmButtonText: yes,
          cancelButtonText: no,
          type: 'warning'
        }).then(async () => {
          resolve()
        }).catch(() => {
          reject()
        });
      })
    }
  }
}